import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { faAngleDown, faAngleUp, faSignOutAlt, faFilePdf, faComments } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Login from './Login';
import ExpandableChat from './ExpandableChat'
import ExpandableClient from './ExpandableClient'
import Expandable from './Expandable';
import ChatWidget from './ChatWidget';
import './App.css';
import logo from './bank-logo.svg';
import {
  faSearchDollar,
  faCalendar,
  faChartLine,
  faGlobeAmericas,
  faMoneyCheckDollar,
  faSquareUpRight,
  faChartPie,
  faHandshake,
  faAddressBook,
  faChartSimple,
  faUsersLine
} from '@fortawesome/free-solid-svg-icons';
import './translations';
import { useTranslation } from 'react-i18next';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [username, setUsername] = useState('');
  const [allExpanded, setAllExpanded] = useState(false);
  const { t, i18n } = useTranslation();
  const [sessionId, setSessionId] = useState(null);

  const generateSessionId = () => {
    const timestamp = Date.now();
    const randomNumber = Math.floor(Math.random() * 100000) + 1;
    return `${timestamp}-${randomNumber}`;
  };

  useEffect(() => {
    setSessionId(generateSessionId());
  }, []);

  const resetSessionId = () => {
    setSessionId(generateSessionId());
  };

  const handleLogin = (authStatus, user) => {
    setIsAuthenticated(authStatus);
    if (authStatus) {
      setUsername(user);
    }
  };

  const toggleExpandables = () => {
    setAllExpanded(!allExpanded);
  };

  const handleLanguageChange = (event) => {
    const newLanguage = event.target.value;
    i18n.changeLanguage(newLanguage);
  };

  function downloadPDF() {
    let pdfUrl;
    let downloadName;

    if (username === 'memo') {
      pdfUrl = "/Banking-Report.pdf";
      downloadName = 'BOA_wealth_report.pdf';
    } else if (username === 'memo1') {
      pdfUrl = "/executive_financial_dashboard.pdf";
      downloadName = 'executive_financial_dashboard.pdf';
    } else {
      pdfUrl = "/executive_financial_dashboard.pdf";
      downloadName = 'executive_financial_dashboard.pdf';
    }

    const link = document.createElement('a');
    link.href = pdfUrl;
    link.download = downloadName;
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
  }


  const today = new Date().toLocaleDateString(i18n.language, {
    weekday: 'long',
    day: '2-digit',
    month: 'long',
    year: 'numeric'
  });

  function logout() {
    i18n.changeLanguage('de');
    setIsAuthenticated(false);
  }

  return (
    <Router>
      <div className="parent-container">
        <Routes>
          <Route path="/login" element={isAuthenticated ? <Navigate to="/" /> : <Login onLogin={handleLogin} />} />
          <Route path="/" element={
            isAuthenticated ? (
              <div className='expandables-parent'>
                {/* <ChatWidget botId="scf" themeColor="#19d0ef" hoverColor="#0595d3" /> */}
                <div className='logout-container'>
                  <img src={logo} alt="login-logo" className='app-logo'/>
                  <div>
                    <FontAwesomeIcon icon={faSignOutAlt} onClick={logout} className="logout-icon" />
                  </div>
                </div>
                <div className='pdf-download-container'>
                  <FontAwesomeIcon icon={faFilePdf} onClick={downloadPDF} className="pdf-icon" />
                </div>
                <div className='language-selection-container'>
                  <select
                    className='language-options'
                    name="languages"
                    id="language-select"
                    onChange={handleLanguageChange}>
                    <option value="de">Deutsch</option>
                    <option value="en">English</option>
                    <option value="fr">Français</option>
                    <option value="es">Español</option>
                    <option value="it">Italiano</option>
                  </select>
                </div>
                <h1 className="app-title">Early Lion AI</h1>
                <h2 className="app-sub-title">
                  {t('greeting')}
                </h2>
                <div className='open-all-button-container'>
                  <div className="date-container">{today}</div>
                  <button className='open-all-button' onClick={toggleExpandables}>
                    {allExpanded ? t('closeAll') : t('openAll')} <FontAwesomeIcon icon={allExpanded ? faAngleUp : faAngleDown} />
                  </button>
                </div>
                {(username === 'memo' || username === 'andreas') && (
                  <>
                  <ChatWidget language={i18n.language} themeColor="#19d0ef" hoverColor="#0595d3" />
                  <Expandable
                    title={t("performance")}
                    summaryApiUrl={`/api/summary?summary_type=performance&language=${i18n.language}`}
                    icon={faChartLine}
                    expanded={allExpanded}
                    language={i18n.language}>
                  </Expandable>
                  <Expandable
                    title={t('regionAllocation')}
                    summaryApiUrl={`/api/summary?summary_type=region_allocation&language=${i18n.language}`}
                    icon={faGlobeAmericas}
                    expanded={allExpanded}
                    language={i18n.language}>
                  </Expandable>
                  <Expandable
                    title={t("assetClassAllocation")}
                    summaryApiUrl={`/api/summary?summary_type=assetclass_allocation&language=${i18n.language}`}
                    icon={faCalendar}
                    expanded={allExpanded}
                    language={i18n.language}>
                  </Expandable>
                  <Expandable
                    title={t("currencyAllocation")}
                    summaryApiUrl={`/api/summary?summary_type=currency_allocation&language=${i18n.language}`}
                    icon={faSearchDollar}
                    expanded={allExpanded}
                    language={i18n.language}>
                  </Expandable>
                  </>
                )}
                {username === 'memo1' && (
                  <>
                    <Expandable
                      title={t("asset_under_management_mgr")}
                      summaryApiUrl={`/api/summary?summary_type=asset_under_mgt_mgr&language=${i18n.language}`}
                      icon={faMoneyCheckDollar}
                      expanded={allExpanded}
                      language={i18n.language}>
                    </Expandable>
                    <Expandable
                      title={t("kpi")}
                      summaryApiUrl={`/api/summary?summary_type=kpi_mgr&language=${i18n.language}`}
                      icon={faChartSimple}
                      expanded={allExpanded}
                      language={i18n.language}>
                    </Expandable>
                    <Expandable
                      title={t("net_new_asset_mgr")}
                      summaryApiUrl={`/api/summary?summary_type=net_new_asset_mgr&language=${i18n.language}`}
                      icon={faSquareUpRight}
                      expanded={allExpanded}
                      language={i18n.language}>
                    </Expandable>
                    <Expandable
                      title={t("income_information_mgr")}
                      summaryApiUrl={`/api/summary?summary_type=income_information_mgr&language=${i18n.language}`}
                      icon={faChartPie}
                      expanded={allExpanded}
                      language={i18n.language}>
                    </Expandable>
                    <Expandable
                      title={t("new_clients_mgr")}
                      summaryApiUrl={`/api/summary?summary_type=new_clients_mgr&language=${i18n.language}`}
                      icon={faHandshake}
                      expanded={allExpanded}
                      language={i18n.language}>
                    </Expandable>
                    <ExpandableChat
                      title={t("chat")}
                      icon={faComments}
                      expanded={allExpanded}
                      sessionId={sessionId}
                      language={i18n}
                      resetSessionId={resetSessionId}
                    />
                  </>
                )}
                {(username === 'memo2' || username === 'andreas2') && (
                  <>
                    <Expandable
                      title={t("asset_under_management_adv")}
                      summaryApiUrl={`/api/summary?summary_type=asset_under_mgt_adv&language=${i18n.language}`}
                      icon={faMoneyCheckDollar}
                      expanded={allExpanded}
                      language={i18n.language}>
                    </Expandable>
                    <Expandable
                      title={t("net_new_asset_adv")}
                      summaryApiUrl={`/api/summary?summary_type=net_new_asset_adv&language=${i18n.language}`}
                      icon={faSquareUpRight}
                      expanded={allExpanded}
                      language={i18n.language}>
                    </Expandable>
                    <Expandable
                      title={t("new_clients_adv")}
                      summaryApiUrl={`/api/summary?summary_type=new_clients_adv&language=${i18n.language}`}
                      icon={faHandshake}
                      expanded={allExpanded}
                      language={i18n.language}>
                    </Expandable>
                    <Expandable
                      title={t("client_contact")}
                      summaryApiUrl={`/api/summary?summary_type=contacted_clients_adv&language=${i18n.language}`}
                      icon={faAddressBook}
                      expanded={allExpanded}
                      language={i18n.language}>
                    </Expandable>
                    <ExpandableClient
                      title={t("customer_note")}
                      icon={faUsersLine}
                      expanded={allExpanded}
                    />
                    <ExpandableChat
                      title={t("chat")}
                      icon={faComments}
                      expanded={allExpanded}
                      sessionId={sessionId}
                      language={i18n}
                      resetSessionId={resetSessionId}
                    />
                  </>
                )}
              </div>
            ) : <Navigate to="/login" />
          } />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
